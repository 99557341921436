export default {
  profileInfo: {},

  employerJobs: [],
  employerJob: {},

  messages: [],
  requestApplications: [],

  dashboardInfo: {},
};
