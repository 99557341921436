import axios from "axios";
// import alert from "@/lang/AlertMessages.js";
import label from "@/lang/StaticLabels.js";
import alert from "@/lang/AlertMessages";

export default {
  // Jobs Actions ------------------------------------------------------------------
  getJobs({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true },
    );
    axios
      .get("jobs/jobs-filter?" + data)
      .then((res) => {
        commit("setJobs", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        commit("setLoading", { status: 0, message: "" }, { root: true });
      });
  },

  getJobDetails({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true },
    );
    axios
      .get("jobs/jobs-details/" + data)
      .then((res) => {
        commit("setJobDetails", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  getJobCategories({ dispatch, commit }) {
    axios
      .get("common/get-job-category-titles")
      .then((res) => {
        commit("setJobCategories", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getCategories({ dispatch, commit }) {
    axios
      .get("common/get-category")
      .then((res) => {
        commit("setCategories", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  // Interns Actions ----------------------------------------------------------
  getInterns({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true },
    );
    axios
      .get("intern/interns-Filter?" + data)
      .then((res) => {
        commit("setInterns", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  getInternDetails({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true },
    );
    axios
      .get("intern/interns-details/" + data)
      .then((res) => {
        commit("setInternDetails", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  // Employers Actions ----------------------------------------------------------
  getEmployers({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true },
    );
    axios
      .get("employer/employers-filter?" + data)
      .then((res) => {
        commit("setEmployers", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  getEmployerDetails({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true },
    );
    axios
      .get("employer/employers-details/" + data)
      .then((res) => {
        commit("setEmployerDetails", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  getEmployerJobs({ dispatch, commit }, data) {
    axios
      .get("jobs/get-employer-jobs/" + data)
      .then((res) => {
        commit("setEmployerJobs", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  // Blog Actions ----------------------------------------------------------
  getBlogs({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true },
    );
    axios
      .get("common/blogs/get-all-blogs?" + data)
      .then((res) => {
        commit("setBlogs", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  getCategoriesBlog({ dispatch, commit }) {
    axios
      .get("common/get-blog-category-titles")
      .then((res) => {
        commit("setCategoriesBlog", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },
  getBlogDetails({ dispatch, commit }, data) {
    axios
      .get("common//blogs/get-blog/" + data)
      .then((res) => {
        commit("setBlogDetails", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getDashboardBlogs({ dispatch, commit }, data) {
    axios
      .get("common/blogs/get-all-blogs?count=" + data)
      .then((res) => {
        commit("setDashboardBlogs", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  // Ads Actions ----------------------------------------------------------
  getAds({ dispatch, commit }, data) {
    axios
      .get("common/ads/get-all-ads/" + data)
      .then((res) => {
        commit("setAds", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getAdDetails({ dispatch, commit }, data) {
    axios
      .get("common/ads/get-ads/" + data)
      .then((res) => {
        commit("setAdDetails", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  // Events Actions ----------------------------------------------------------
  getEvents({ dispatch, commit }) {
    axios
      .get("common/events/get-all-events")
      .then((res) => {
        commit("setEvents", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getEventDetails({ dispatch, commit }, data) {
    axios
      .get("common//events/get-event/" + data)
      .then((res) => {
        commit("setEventDetails", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  // Banners and Packages Actions ----------------------------------------------------------
  getBanners({ dispatch, commit }) {
    axios
      .get("common/banners/get-all-banners")
      .then((res) => {
        commit("setBanners", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getPackages({ dispatch, commit }) {
    axios
      .get("common/packages/get-all-package")
      .then((res) => {
        commit("setPackages", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getPackageDetails({ dispatch, commit }, data) {
    axios
      .get("common/packages/get-package/" + data)
      .then((res) => {
        commit("setPackageDetails", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  // Page Content Actions ----------------------------------------------------------
  getHomePageContent({ dispatch, commit }) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true },
    );
    axios
      .get("common/get-all-contents/main")
      .then((res) => {
        commit("setHomePageContent", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  getAboutPageContent({ dispatch, commit }) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true },
    );
    axios
      .get("common/get-all-contents/about")
      .then((res) => {
        commit("setAboutPageContent", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  getRulePageContent({ dispatch, commit }) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true },
    );
    axios
      .get("common/get-all-contents/introduction")
      .then((res) => {
        commit("setRulePageContent", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  getTeamList({ dispatch, commit }) {
    axios
      .get("common/get-all-teams")
      .then((res) => {
        commit("setTeamList", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getCompanies({ dispatch, commit }) {
    axios
      .get("common/company/get-all-company")
      .then((res) => {
        commit("setCompanies", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getFaqList({ dispatch, commit }) {
    axios
      .get("common/get-all-faq")
      .then((res) => {
        commit("setFaqList", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getQuizList({ dispatch, commit }) {
    axios
      .get("common/get-all-quiz")
      .then((res) => {
        commit("setQuizList", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  // Contact Us Form -------------------------------------------------------------------------------------
  sendContactMessage({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("common/create-contact", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.messageSent,
            redirect: false,
          },
          { root: true },
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 800);
      });
  },

  //    Country List  --------------------------------------------------------------------------------------
  getCountries({ dispatch, commit }) {
    axios
      .get("common/get-all-country")
      .then((res) => {
        commit("setCountries", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },
};
