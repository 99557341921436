export default {
  // Static Getters ---------------------------------------------------------------
  featureItems() {
    return [
      {
        id: "1",
        pic: require("@/assets/images/shabakeh/l-1.png"),
        title: "جونیور برنامه نویس Php",
        expertise: "CSS3, HTML5, Javascript, Bootstrap, Jquery",
        location: "تهران",
        salary: "20,000,000",
        feature: "ویژه",
        urgent: "فوری",
        time: "کارآموزی",
        timeId: 1,
      },
      {
        id: "2",
        pic: require("@/assets/images/shabakeh/l-4.png"),
        title: "جونیور برنامه نویس Php",
        expertise: "CSS3, HTML5, Javascript, Bootstrap, Jquery",
        location: "تهران",
        salary: "20,000,000",
        feature: "ویژه",
        time: "تمام وقت",
        timeId: 2,
      },
      {
        id: "3",
        pic: require("@/assets/images/shabakeh/l-5.png"),
        title: "جونیور برنامه نویس Php",
        expertise: "CSS3, HTML5, Javascript, Bootstrap, Jquery",
        location: "تهران",
        salary: "20,000,000",
        feature: "ویژه",
        time: "پاره وقت",
        timeId: 3,
      },
      {
        id: "4",
        pic: require("@/assets/images/shabakeh/l-7.png"),
        title: "جونیور برنامه نویس Php",
        expertise: "CSS3, HTML5, Javascript, Bootstrap, Jquery",
        location: "تهران",
        salary: "20,000,000",
        feature: "ویژه",
        urgent: "فوری",
        time: "فریلنسر",
        timeId: 4,
      },
      {
        id: "5",
        pic: require("@/assets/images/shabakeh/l-4.png"),
        title: "جونیور برنامه نویس Php",
        expertise: "CSS3, HTML5, Javascript, Bootstrap, Jquery",
        location: "تهران",
        salary: "20,000,000",
        feature: "ویژه",
        urgent: "فوری",
        time: "تمام وقت",
        timeId: 2,
      },
    ];
  },

  blogItems() {
    return [
      {
        id: "1",
        imageAddress: require("@/assets/images/shabakeh/blog2.jpg"),
        createdLocal: "6 اردیبهشت ۱۴۰۱",
        category: "مصاحبه",
        viwe: 20,
        title: "تعطیلات همراه با درآمد",
        description:
          "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است ",
      },
      {
        id: "2",
        imageAddress: require("@/assets/images/shabakeh/blog3.jpg"),
        createdLocal: "6 اردیبهشت ۱۴۰۱",
        category: "مصاحبه",
        viwe: 20,
        title: "تعطیلات همراه با درآمد",
        description:
          "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است ",
      },
      {
        id: "3",
        imageAddress: require("@/assets/images/shabakeh/blog1.jpg"),
        createdLocal: "6 اردیبهشت ۱۴۰۱",
        category: "مصاحبه",
        viwe: 20,
        title: "تعطیلات همراه با درآمد",
        description:
          "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است ",
      },
    ];
  },

  categoryItems() {
    return [
      {
        id: "accounting",
        icon: "icon-accounting",
        title: "حسابداری و مالی",
        count: "5",
      },
      {
        id: "marketing",
        icon: "icon-marketing",
        title: "بازاریابی",
        count: "5",
      },
      {
        id: "design",
        icon: "icon-design",
        title: "طراحی",
        count: "5",
      },
      {
        id: "programming",
        icon: "icon-web-programming",
        title: "برنامه نویسی",
        count: "5",
      },
      {
        id: "human",
        icon: "icon-human-resources",
        title: "منابع انسانی",
        count: "5",
      },
      {
        id: "project",
        icon: "icon-project",
        title: "مدیریت پروژه",
        count: "5",
      },
      {
        id: "service",
        icon: "icon-customer-service",
        title: "خدمات مشتری",
        count: "5",
      },
      {
        id: "care",
        icon: "icon-heart-beat",
        title: "بهداشت و مراقبت",
        count: "5",
      },
    ];
  },

  homeJobCheckItem() {
    return [
      { id: 1, title: "موقعیت برد  برد را در کار خود ایجاد کنید" },
      { id: 2, title: "از بهترین آموزش ها استفاده کنید" },
      { id: 3, title: "شغل مورد علاقه خود را پیدا کنید" },
      { id: 4, title: "رزومه خود را به آسانی آپلود کنید" },
    ];
  },

  companyLogos() {
    return [
      {
        id: 1,
        pic: require("@/assets/images/shabakeh/layar-white.svg"),
      },
      {
        id: 2,
        pic: require("@/assets/images/shabakeh/mailchimp-white.svg"),
      },
      {
        id: 3,
        pic: require("@/assets/images/shabakeh/forbes-white.svg"),
      },
      {
        id: 4,
        pic: require("@/assets/images/shabakeh/layar-white.svg"),
      },
      {
        id: 5,
        pic: require("@/assets/images/shabakeh/mailchimp-white.svg"),
      },
      {
        id: 6,
        pic: require("@/assets/images/shabakeh/layar-white.svg"),
      },
    ];
  },

  sliderItems() {
    return [
      {
        id: "a",
        img: require("@/assets/images/shabakeh/agah-logo.png"),
        title: "همایش معامله گری در بازار سرمایه",
        tag1: "بازار سرمایه",
        tag2: "معامله گری",
        tag3: "همایش",
        location: "تهران، شهرک غرب",
        price: "2 میلیون تومان",
        text: "۱ - ۱۴ مرداد - روز های زوج ساعت ۱ تا ۵ بعد از ظهر",
      },
      {
        id: "b",
        img: require("@/assets/images/shabakeh/agah-logo.png"),
        title: "همایش معامله گری در بازار سرمایه",
        tag1: "بازار سرمایه",
        tag2: "معامله گری",
        tag3: "همایش",
        location: "تهران، شهرک غرب",
        price: "2 میلیون تومان",
        text: "۱ - ۱۴ مرداد - روز های زوج ساعت ۱ تا ۵ بعد از ظهر",
      },
      {
        id: "c",
        img: require("@/assets/images/shabakeh/agah-logo.png"),
        title: "همایش معامله گری در بازار سرمایه",
        tag1: "بازار سرمایه",
        tag2: "معامله گری",
        tag3: "همایش",
        location: "تهران، شهرک غرب",
        price: "2 میلیون تومان",
        text: "۱ - ۱۴ مرداد - روز های زوج ساعت ۱ تا ۵ بعد از ظهر",
      },
      {
        id: "d",
        img: require("@/assets/images/shabakeh/agah-logo.png"),
        title: "همایش معامله گری در بازار سرمایه",
        tag1: "بازار سرمایه",
        tag2: "معامله گری",
        tag3: "همایش",
        location: "تهران، شهرک غرب",
        price: "2 میلیون تومان",
        text: "۱ - ۱۴ مرداد - روز های زوج ساعت ۱ تا ۵ بعد از ظهر",
      },
      {
        id: "e",
        img: require("@/assets/images/shabakeh/agah-logo.png"),
        title: "همایش معامله گری در بازار سرمایه",
        tag1: "بازار سرمایه",
        tag2: "معامله گری",
        tag3: "همایش",
        location: "تهران، شهرک غرب",
        price: "2 میلیون تومان",
        text: "۱ - ۱۴ مرداد - روز های زوج ساعت ۱ تا ۵ بعد از ظهر",
      },
      {
        id: "f",
        img: require("@/assets/images/shabakeh/agah-logo.png"),
        title: "همایش معامله گری در بازار سرمایه",
        tag1: "بازار سرمایه",
        tag2: "معامله گری",
        tag3: "همایش",
        location: "تهران، شهرک غرب",
        price: "2 میلیون تومان",
        text: "۱ - ۱۴ مرداد - روز های زوج ساعت ۱ تا ۵ بعد از ظهر",
      },
    ];
  },

  sortOptions() {
    return [
      { value: "newest", text: "جدیدترین" },
      { value: "oldest", text: "قدیمی ترین" },
      { value: "random", text: "نتایج تصادفی" },
    ];
  },

  employerSortOptions() {
    return [
      { value: "newest", text: "جدیدترین" },
      { value: "oldest", text: "قدیمی ترین" },
      { value: "random", text: "نتایج تصادفی" },
      { value: "jobCount", text: "بیشترین فرصت شغلی" },
    ];
  },

  employmentType() {
    return [
      { value: "Full-time", text: "تمام وقت" },
      { value: "Part-time", text: "پاره وقت" },
      { value: "Internship", text: "کارآموزی" },
      { value: "Remote", text: "فریلنسر" },
    ];
  },

  experienceItems() {
    return [
      { value: 1, text: "۱ سال" },
      { value: 2, text: "۲ سال" },
      { value: 3, text: "۳ سال" },
      { value: 4, text: "۴ سال" },
      { value: 5, text: "بالای ۵ سال" },
      { value: 10, text: "بالای 10 سال" },
    ];
  },

  experienceForAddItems() {
    return [
      { value: 0, text: "کمتر از یک سال" },
      { value: 1, text: "۱ سال" },
      { value: 2, text: "۲ سال" },
      { value: 3, text: "۳ سال" },
      { value: 4, text: "۴ سال" },
      { value: 5, text: "بالای ۵ سال" },
      { value: 10, text: "بالای 10 سال" },
    ];
  },

  experienceItemsProfile() {
    return [
      { value: "1", text: "۱ سال" },
      { value: "2", text: "۲ سال" },
      { value: "3", text: "۳ سال" },
      { value: "4", text: "۴ سال" },
      { value: "5", text: "بالای ۵ سال" },
      { value: "10", text: "بالای 10 سال" },
    ];
  },

  countryOptions() {
    return [
      { value: "ایران", text: "ایران" },
      { value: "ترکیه", text: "ترکیه" },
      { value: "آلمان", text: "آلمان" },
      { value: "انگلستان", text: "انگلستان" },
      { value: "سوئد", text: "سوئد" },
      { value: "سوییس", text: "سوییس" },
      { value: "هلند", text: "هلند" },
      { value: "اتریش", text: "اتریش" },
      { value: "دانمارک", text: "دانمارک" },
      { value: "آمریکا", text: "ایالات متحده آمریکا" },
      { value: "کانادا", text: "کانادا" },
      { value: "قبرس", text: "قبرس" },
      { value: "امارات", text: "امارات متحده عربی" },
      { value: "کویت", text: "کویت" },
      { value: "عمان", text: "عمان" },
    ];
  },

  cityOptions() {
    return [
      { value: "آذربایجان غربی", text: "آذربایجان غربی" },
      { value: "آذربایجان شرقی", text: "آذربایجان شرقی" },
      { value: "اردبیل", text: "اردبیل" },
      { value: "اصفهان", text: "اصفهان" },
      { value: "البرز", text: "البرز" },
      { value: "ایلام", text: "ایلام" },
      { value: "بوشهر", text: "بوشهر" },
      { value: "تهران", text: "تهران" },
      { value: "چهارمحال", text: "چهارمحال و بختیاری" },
      { value: "خراسان شمالی", text: "خراسان شمالی" },
      { value: "خراسان رضوی", text: "خراسان رضوی" },
      { value: "خراسان جنوبی", text: "خراسان جنوبی" },
      { value: "خوزستان", text: "خوزستان" },
      { value: "زنجان", text: "زنجان" },
      { value: "سمنان", text: "سمنان" },
      { value: "سیستان", text: "سیستان و بلوچستان" },
      { value: "فارس", text: "فارس" },
      { value: "قم", text: "قم" },
      { value: "قزوین", text: "قزوین" },
      { value: "کردستان", text: "کردستان" },
      { value: "کرمان", text: "کرمان" },
      { value: "گلستان", text: "گلستان" },
      { value: "گیلان", text: "گیلان" },
      { value: "مازندران", text: "مازندران" },
      { value: "مرکزی", text: "مرکزی" },
      { value: "هرمزگان", text: "هرمزگان" },
      { value: "همدان", text: "همدان" },
    ];
  },

  educationOptions() {
    return [
      { value: "Under Diploma", text: "زیر دیپلم" },
      { value: "Diploma", text: "دیپلم" },
      { value: "Bachelor", text: "کارشناسی" },
      { value: "Master", text: "کارشناسی ارشد" },
      { value: "PhD", text: "دکتری" },
    ];
  },

  roleOptions() {
    return [
      { value: "مدیر کل", text: "مدیر کل" },
      { value: "مدیر داخلی", text: "مدیر داخلی" },
      { value: "ناظر محتوا", text: "ناظر " },
      { value: "پرسنل اداری", text: "پرسنل اداری" },
      { value: "کاربر عادی", text: "کاربر عادی" },
    ];
  },

  testUsers() {
    return [
      {
        id: 1,
        name: "امیر احمدی",
        username: "admin",
        city: "تهران",
        role: "مدیر کل",
        status: 1,
      },
      {
        id: 2,
        name: "سمیرا حسینی",
        username: "samira",
        city: "تهران",
        role: "مدیر داخلی",
        status: 1,
      },
      {
        id: 3,
        name: "ابراهیم رزاقی",
        username: "eRazzaghi",
        city: "تبریز",
        role: "پرسنل اداری",
        status: 1,
      },
      {
        id: 4,
        name: "زهرا معلمی",
        username: "ZMoalemi",
        city: "تهران",
        role: "پرسنل اداری",
        status: 0,
      },
      {
        id: 5,
        name: "فاطمه اخترآبادی",
        username: "akhtarabadi",
        city: "زاهدان",
        role: "کاربر عادی",
        status: 1,
      },
      {
        id: 6,
        name: "حسین ایرانی مفرد",
        username: "hIrani",
        city: "تهران",
        role: "کاربر عادی",
        status: 0,
      },
      {
        id: 7,
        name: "سارینا یزدانی",
        username: "sarina",
        city: "قم",
        role: "کاربر عادی",
        status: 1,
      },
    ];
  },
};
