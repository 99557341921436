let language = localStorage.getItem("selectedLanguage");
let label;
if (language && language === "En") {
  label = {
    page: {
      home: "Home",
      dashboard: "Dashboard",
      aboutUs: "About Us",
      contactUs: "Contact Us",
      baseInformation: "Base Informations",
      users: "Users",
      products: "Products",
      addProduct: "New Product",
      editProduct: "Edit Product",
      orders: "Orders",
      addOrder: "New Order",
      editOrder: "Edit Order",
      support: "Support",
      tickets: "Tickets",
      addTicket: "Open Ticket",
      messages: "Messages",
      profile: "Profile",
      login: "Sign In",
      register: "Sign Up",
      notFound: "Oops! Are You Lost ?!",
      notAccess: "Sorry! You Don't Have Access To This Page",
      noAccess: "No Access",
      serverError: "Internal Server Error! Please Call The Technical Support",
      underConstruction:
        "This Page is Under Construction and will be Available Soon",
    },
    menu: {
      home: "Home",
      dashboard: "Dashboard",
      about: "About Us",
      contact: "Contact Us",
      users: "Users",
      messages: "Messages",
      tickets: "Tickets",
      support: "Support",
      products: "Products",
      manageProducts: "Products Management",
      orders: "Orders",
      addOrder: "New Order",
      cart: "Cart",
      shop: "Shop",
      baseInformation: "Base Informations",
      reports: "Reports",
      editProfile: "Update Profile",
      notifications: "Notifications",
      allNotifications: "Show all Notifications",
      allMessages: "Show all Messages",
      logout: "Logout",
      rejectedTemplate: "Blocked Account",
    },
    button: {
      login: "Sign In",
      register: "Sign Up",
      logout: "Logout",
      recoveryEmail: "Send Recovery Email",
      submit: "Submit",
      submitInfo: "Submit Informations",
      save: "Save",
      saveChanges: "Save Changes",
      add: "Add New",
      addUser: "New User",
      edit: "Update",
      delete: "Delete",
      confirmDelete: "Yes, Delete",
      editProfile: "Update Profile",
      editPassword: "Update Password",
      send: "Send",
      back: "Back",
      backToLogin: "Back to Login",
      backToHome: "Back to Home",
      home: "Home",
      cancel: "Cancel",
      addBase: "Add Base Info",
      reload: "Reload",
      addProduct: "Save Product",
      editProduct: "Update Profile",
    },
    table: {
      row: "#",
      actions: "Actions",
      title: "Title",
      qty: "Quantity",
      category: "Category",
      subject: "Subject",
      status: "Status",
      name: "Full Name",
      username: "Username",
      city: "City",
      role: "User Role",
      active: "Active",
      inactive: "Inactive",
    },
    form: {
      firstName: "First Name",
      lastName: "Last Name",
      fullName: "Full Name",
      userName: "Username",
      password: "Password",
      repeatPassword: "Repeat Password",
      currentPassword: "Current Password",
      newPassword: "New Password",
      email: "Email Address",
      mobile: "Mobile Number",
      tel: "Phone Number",
      phone: "Telephone",
      address: "Address",
      zipCode: "Zip Code",
      otpCode: "OTP Code",
      image: "Image",
      file: "File",
      fileSize: "Max File Size",
      fileType: "File Types",
      fileRatio: "Better To Use Square Ratio Picture",
      department: "Department",
      birthDate: "Birth Date",
      description: "Description",
      content: "Content",
      message: "Message",
      subject: "Subject",
      title: "Title",
      qty: "Quantity",
      digit: "Digit",
      category: "Category",
      city: "City",
      role: "User Role",
      productName: "Product Name",
      annualDemand: "Annual Demand",
      sbu: "SBU",
      resell: "Resell",
      weight: "Special Weight",
      sendDate: "Sent Date",
      productDate: "Production Date",
      paymentMethod: "Payment Method",
      cashOption: "Cash",
      chequeOption: "Cheque",
      attachment: "Attachment",
      dropzoneTxt: "Click or Drop File here",
    },
    caption: {
      yes: "Yes",
      no: "No",
      ok: "Ok",
      dashboard: "Dashboard",
      profile: "Update Profile",
      password: "Change Password",
      avatar: "Profile Avatar",
      baseInformation: "Basic Informations",
      addBaseInformation: "Add Basic Info",
      editBaseInformation: "Update Basic Info",
      users: "Users Management",
      addUser: "New User",
      editUser: "Update User",
      products: "Products Management",
      orders: "Manage Orders",
      messages: "Messages and Chats",
      tickets: "Tickets",
      loading: "Loading ...",
      singIn: "Sign in",
      singUp: "Sign Up",
      haveAccount: "Do you have Account ?",
      haveNotAccount: "Don't have Account ?",
      forgotPassword: "Forgot Password ?",
      emptyItem: "Item Not Found",
      emptyMessage: "There is no Message",
      emptyTicket: "There is no Tickets",
      emptyNotify: "There is no new Notifications",
      noMessage: "There is no new Message",
      noNotify: "There is no new Notification",
      underConstruction:
        "This Section is Under Construction and will be available soon",
      rejectedTitle: "No Access",
      rejectedText: "Your Account has been blocked or Reported",
      search: "Search",
      searchTable: "Search Items",
      searchAllColumns: "Search in all columns",
      delConfirm: "Are you sure you want to delete this item ?",
      resell: "is this Item for Resell ?",
    },
  };
} else {
  label = {
    page: {
      home: "صفحه اصلی",
      dashboard: "داشبورد",
      aboutUs: "درباره ما",
      contactUs: "تماس با ما",
      faq: "سوالات متداول",
      packages: "پکیج ها",
      rules: "قوانین و مقررات",
      jobs: "فرصت های کارآموزی",
      baseInformation: "اطلاعات پایه",
      users: "کاربران و پرسنل",
      products: "محصولات",
      productDetails: "اطلاعات محصول",
      addProduct: "ثبت محصول",
      editProduct: "ویرایش اطلاعات محصول",
      orders: "سفارشات مشتریان",
      addOrder: "ثبت سفارش",
      editOrder: "ویرایش سفارش",
      support: "پشتیبانی",
      tickets: "تیکت پشتیبانی",
      addTicket: "ثبت تیکت پشتیبانی",
      messages: "پیام‌ها",
      profile: "پروفایل کاربری",
      login: "ورود به سامانه",
      register: "عضویت",
      notFound: "راهتان را گم کرده‌اید ؟!",
      notAccess: "متأسفانه دسترسی شما برای مشاهده این قسمت محدود شده است",
      noAccess: "عدم دسترسی",
      serverError: "خطای ارتباط با سرور؛ لطفا به پشتیبانی فنی اطلاع دهید",
      underConstruction: "صفحه مدنظر در حال توسعه و برورسانی می‌باشد",
    },
    menu: {
      home: "صفحه اصلی",
      dashboard: "داشبورد",
      about: "درباره ما",
      blog: "مقالات",
      contactUs: "تماس با ما",
      contact: "راه های ارتباطی",
      users: "کاربران و پرسنل",
      messages: "پیام‌ها",
      tickets: "تیکت‌ها",
      support: "پشتیبانی",
      manageProducts: "مدیریت محصولات",
      products: "محصولات",
      orders: "سفارشات",
      addOrder: "ثبت سفارش",
      cart: "سبد خرید",
      shop: "فروشگاه",
      baseInformation: "اطلاعات پایه",
      reports: "گزارشات",
      editProfile: "ویرایش پروفایل",
      notifications: "اعلان و هشدار‌ها",
      allNotifications: "مشاهده همه اعلان‌ها",
      allMessages: "مشاهده همه پیام‌ها",
      logout: "خروج",
      rejectedTemplate: "کاربر مسدود شده",
      logOut: "خروج",
    },
    button: {
      login: "ورود به سامانه",
      register: "ثبت نام ",
      logout: "خروج از سامانه",
      recoveryEmail: "ارسال ایمیل بازیابی",
      submit: "ثبت",
      submitInfo: "ثبت اطلاعات",
      save: "ذخیره",
      saveChanges: "ثبت تغییرات",
      add: "ثبت جدید",
      addUser: "معرفی کاربر جدید",
      edit: "ویرایش",
      delete: "حذف",
      confirmDelete: "بله، حذف شود",
      editProfile: "ویرایش پروفایل",
      editPassword: "ویرایش رمز عبور",
      send: "ارسال",
      back: "بازگشت",
      backToLogin: "بازگشت به صفحه ورود",
      backToHome: "بازگشت به صفحه اصلی",
      home: "صفحه اصلی",
      cancel: "انصراف",
      addBase: "ثبت اطلاعات پایه",
      reload: "بارگذاری مجدد",
      addProduct: "ثبت محصول",
      editProduct: "ویرایش محصول",
    },
    table: {
      row: "ردیف",
      actions: "عملیات",
      title: "عنوان",
      qty: "تعداد",
      category: "دسته بندی",
      subject: "موضوع",
      status: "وضعیت",
      name: "نام و نام خانوادگی",
      username: "نام کاربری",
      city: "شهر / استان",
      role: "نقش کاربر",
      active: "فعال",
      inactive: "غیرفعال",
    },
    form: {
      firstName: "نام",
      lastName: "نام خانوادگی",
      fullName: "نام و نام خانوادگی",
      userName: "نام کاربری",
      password: "رمز عبور",
      repeatPassword: "تکرار رمز عبور",
      currentPassword: "رمز عبور فعلی",
      newPassword: "رمز عبور جدید",
      email: "پست الکترونیک",
      mobile: "تلفن همراه",
      tel: "شماره تماس",
      phone: "تلفن ثابت",
      address: "آدرس",
      zipCode: "کد پستی",
      otpCode: "کد احراز هویت (کد تأیید)",
      image: "تصویر",
      file: "فایل",
      fileSize: "حداکثر حجم مجاز",
      fileType: "پسوندهای مجاز",
      fileRatio: "تصویر در ابعاد مربعی انتخاب کنید",
      department: "دپارتمان",
      birthDate: "تاریخ تولد",
      description: "توضیحات",
      content: "متن و محتوا",
      message: "متن پیام",
      subject: "موضوع",
      title: "عنوان",
      qty: "تعداد",
      digit: "عدد",
      category: "دسته بندی",
      city: "شهر / استان",
      role: "نقش کاربر",
      productName: "نام / عنوان محصول",
      annualDemand: "مقدار نیاز سالانه",
      sbu: "واحد کسب و کار (SBU)",
      resell: "فروش مجدد",
      weight: "وزن مخصوص",
      sendDate: "تاریخ ارسال",
      productDate: "تاریخ تولید",
      paymentMethod: "نحوه پرداخت",
      cashOption: "پرداخت نقدی",
      chequeOption: "چک بانکی",
      attachment: "فایل پیوست",
      dropzoneTxt: "کلیک و یا فایل را در اینجا رها کنید",
    },
    caption: {
      yes: "بله",
      no: "خیر",
      ok: "تایید",
      dashboard: "داشبورد مدیریتی",
      profile: "ویرایش اطلاعات و رمز عبور حساب کاربری",
      password: "تغییر رمز عبور",
      avatar: "تصویر پروفایل کاربری",
      baseInformation: "اطلاعات و تنظیمات ابتدایی سامانه",
      addBaseInformation: "ثبت اطلاعات پایه جدید",
      editBaseInformation: "ویرایش اطلاعات پایه",
      users: "مدیریت کاربران سامانه",
      addUser: "ثبت کاربر جدید",
      editUser: "ویرایش اطلاعات کاربر",
      products: "مدیریت محصولات و تولیدات",
      orders: "مدیریت و بررسی سفارشات",
      messages: "پیام‌ها و چت کاربران",
      tickets: "پیام‌ها و درخواست‌های مشتریان",
      loading: "در حال بارگذاری ...",
      singIn: "ورود به سامانه",
      singUp: "عضویت در سامانه",
      haveAccount: "حساب کاربری دارید؟",
      haveNotAccount: "حساب کاربری ندارید؟",
      forgotPassword: "رمز عبور را فراموش کرده‌اید؟",
      emptyItem: "موردی جهت نمایش یافت نشد",
      emptyMessage: "لیست پیام‌های شما خالی می‌باشد",
      emptyTicket: "لیست تیکت‌های شما خالی می‌باشد",
      emptyNotify: "لیست اعلان‌ و هشدارها خالی می‌باشد",
      noMessage: "پیام جدیدی دریافت نکردید",
      noNotify: "اعلان جدیدی وجود ندارد",
      underConstruction:
        "این قسمت در حال توسعه می‌باشد و بزودی در دسترس قرار خواهد گرفت",
      rejectedTitle: "عدم دسترسی به سامانه",
      rejectedText: "حساب کاربری شما در این سامانه مسدود شده است",
      search: "جستجو",
      searchTable: "جستجو در جدول",
      searchAllColumns: "جستجو در همه ستون‌ها",
      delConfirm: "از حذف این مورد مطمئن هستید؟",
      resell: "آیا محصول برای فروش مجدد هست؟",
    },
  };
}

export default label;
