export default {
  jobs: [],
  jobDetails: {},
  jobCategories: [],
  categories: [],

  interns: [],
  internDetails: {},

  employers: [],
  employerDetails: {},
  employerJobs: [],

  blogs: [],
  dashboardBlogs: [],
  blogDetails: {},
  categoriesBlog: [],

  ads: [],
  adDetails: {},

  events: [],
  eventDetails: {},

  banners: [],
  packages: [],
  packageDetails: {},

  aboutPageContent: [],
  homePageContent: [],
  rulePageContent: [],

  teamList: [],
  companies: [],
  faqList: [],
  quizList: [],

  countries: [],
};
