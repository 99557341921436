import axios from "axios";
import alert from "@/lang/AlertMessages.js";
// import label from "@/lang/StaticLabels.js";

export default {
  // Intern Profile Actions -------------------------------------------------------------------------------
  getProfileInfo({ dispatch, commit }) {
    axios
      .get("intern/get-intern-info")
      .then((res) => {
        commit("setProfileInfo", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  editProfile({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .patch("intern/update-intern", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.editProfile,
            redirect: false,
          },
          { root: true },
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 800);
      });
  },

  editProfilePicture({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("intern/interns-add-profilePicture", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.changeAvatar,
            redirect: false,
          },
          { root: true },
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 800);
      });
  },

  // Intern Messages Actions  --------------------------------------------------
  sendMessage({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("messages/send-message", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.messageSent,
            redirect: false,
          },
          { root: true },
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 800);
      });
  },

  // Intern Request Application Actions -------------------------------------------------------------------------------
  getRequestApplications({ dispatch, commit }) {
    axios
      .get("application/get-request-applications")
      .then((res) => {
        commit("setRequestApplications", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  sendRequestApplication({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("application/send-request-applications", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.requestSent,
            redirect: false,
          },
          { root: true },
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 800);
      });
  },

  // Payment Actions -----------------------------------------------------------------------------------------------
  paymentMethod({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .get("payments/payments-product/" + data)
      .then((res) => {
        commit("setPaymentLink", res.data.url);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 800);
      });
  },

  getPaymentList({ dispatch, commit }) {
    axios
      .get("payments/get-user-payments")
      .then((res) => {
        commit("setPaymentList", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  // Dashboard Informations  -----------------------------------------------------------------------------------
  getDashboardInfo({ dispatch, commit }) {
    axios
      .get("intern/get-intern-count-info")
      .then((res) => {
        commit("setDashboardInfo", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },
};
