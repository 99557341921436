let navItemsIntern = [
  {
    to: "InternDashboard",
    label: "داشبورد",
    icon: "mdi-gauge-empty",
    active: false,
    subItems: false,
  },
  {
    to: "InternProfile",
    label: "پروفایل",
    icon: "mdi-account-outline",
    active: false,
    subItems: false,
  },
  {
    to: "InternMessages",
    label: "پیام ها",
    icon: "mdi-message-fast-outline",
    active: false,
    subItems: false,
  },
  {
    to: "InternPayments",
    label: "پرداخت ها",
    icon: "mdi-credit-card-outline",
    active: false,
    subItems: false,
  },
  {
    to: "InternRequests",
    label: "درخواست های من",
    icon: "mdi-package-variant-closed",
    active: false,
    subItems: false,
  },
  {
    to: "InternQuizResults",
    label: "تست های هوش مصنوعی من",
    icon: "mdi-format-list-checks",
    active: false,
    subItems: false,
  },
];

export default navItemsIntern;
