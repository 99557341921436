import Vue from "vue";
import Vuex from "vuex";

import actions from "./actions";
import state from "./state";
import mutations from "./mutations";
import getters from "./getters";

import baseX from "./modules/baseX";
import employerX from "./modules/employerX";
import internX from "./modules/internX";
import staticX from "./modules/staticX";
import quizX from "./modules/quizX";

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    baseX,
    employerX,
    internX,
    staticX,
    quizX,
  },
});
