export default {
  setJobs(state, data) {
    state.jobs = data;
  },
  setJobDetails(state, data) {
    state.jobDetails = data.job;
  },
  setJobCategories(state, data) {
    state.jobCategories = data;
  },

  setCategories(state, data) {
    state.categories = data;
  },

  setInterns(state, data) {
    state.interns = data;
  },
  setInternDetails(state, data) {
    state.internDetails = data;
  },

  setEmployers(state, data) {
    state.employers = data;
  },
  setEmployerDetails(state, data) {
    state.employerDetails = data;
  },
  setEmployerJobs(state, data) {
    state.employerJobs = data;
  },

  setBlogs(state, data) {
    state.blogs = data;
  },
  setDashboardBlogs(state, data) {
    state.dashboardBlogs = data;
  },
  setBlogDetails(state, data) {
    state.blogDetails = data;
  },
  setCategoriesBlog(state, data) {
    state.categoriesBlog = data;
  },

  setAds(state, data) {
    state.ads = data;
  },
  setAdDetails(state, data) {
    state.adDetails = data;
  },

  setEvents(state, data) {
    state.events = data;
  },
  setEventDetails(state, data) {
    state.eventDetails = data;
  },

  setBanners(state, data) {
    state.banners = data;
  },

  setPackages(state, data) {
    state.packages = data;
  },
  setPackageDetails(state, data) {
    state.packageDetails = data;
  },

  setAboutPageContent(state, data) {
    state.aboutPageContent = data;
  },

  setHomePageContent(state, data) {
    state.homePageContent = data;
  },

  setRulePageContent(state, data) {
    state.rulePageContent = data;
  },

  setTeamList(state, data) {
    state.teamList = data;
  },

  setCompanies(state, data) {
    state.companies = data;
  },

  setFaqList(state, data) {
    state.faqList = data;
  },

  setQuizList(state, data) {
    state.quizList = data;
  },

  setCountries(state, data) {
    state.countries = data;
  },
};
