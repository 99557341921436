<template>
  <v-app>
    <the-header />

    <panel-sidebar />

    <v-main class="panel-content">
      <transition name="fade" mode="out-in">
        <router-view :key="$route.path"></router-view>
      </transition>
    </v-main>

    <the-loading />
  </v-app>
</template>

<script>
import TheHeader from "@/containers/TheHeader";
import PanelSidebar from "@/containers/PanelSidebar";
import TheLoading from "@/components/TheLoading";

export default {
  name: "TheContainer",
  components: {
    TheHeader,
    TheLoading,
    PanelSidebar,
  },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.panel-content .v-main__wrap {
  background-color: #f8f9fa;
  color: #4f5e64;
}
</style>
