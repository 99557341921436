let navItems = [
  {
    to: "Home",
    label: "صفحه اصلی",
    icon: "mdi-home",
    active: false,
    subItems: false,
  },
  {
    to: "About",
    label: "درباره ما",
    icon: "mdi-information",
    active: false,
    subItems: false,
  },
  {
    to: "JobsList",
    label: "فرصت های کارآموزی",
    icon: "mdi-store",
    active: false,
    subItems: false,
  },
  {
    to: "EmployersList",
    label: "کارفرمایان",
    icon: "mdi-account-tie",
    active: false,
    subItems: false,
  },
  {
    to: "InternsList",
    label: "کارآموزان",
    icon: "mdi-account-school",
    active: false,
    subItems: false,
  },
  {
    to: "BlogList",
    label: "مقالات",
    icon: "mdi-post-outline",
    active: false,
    subItems: false,
  },
  {
    to: "Contact",
    label: "تماس با ما",
    icon: "mdi-phone",
    active: false,
    subItems: false,
  },
  {
    label: "رویداد ها",
    to: "EventsList",
    icon: 'mdi-bullhorn',
    active: false,
    subItems: false,
  },
  {
    label: "تست هوش مصنوعی",
    icon: 'mdi-brain',
    to: "GuizList",
    active: false,
    subItems: false,
  },
  {
    to: "Faq",
    label: "سوالات متداول",
    icon: "mdi-frequently-asked-questions",
    active: false,
    subItems: false,
  },
  {
    to: "Package",
    label: "پکیج ها",
    icon: "mdi-package-variant-closed",
    active: false,
    subItems: false,
  },
];

export default navItems;
