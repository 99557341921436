export default {
  otpCode: (state) => {
    return state.otpCode;
  },

  loginCheck: (state) => {
    return state.loginCheck;
  },

  sendCode: (state) => {
    return state.sendCode;
  },

  postCheck: (state) => {
    return state.postCheck;
  },
  loadingBtn: (state) => {
    return state.loadingBtn;
  },

  test() {
    let test =
      '<style>.h_iframe-aparat_embed_frame{position:relative;}.h_iframe-aparat_embed_frame .ratio{display:block;width:100%;height:auto;}.h_iframe-aparat_embed_frame iframe{position:absolute;top:0;left:0;width:100%;height:100%;}</style><div class="h_iframe-aparat_embed_frame"><span style="display: block;padding-top: 57%"></span><iframe src="https://www.aparat.com/video/video/embed/videohash/KQECF/vt/frame"  allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe></div>';
    var src = test.split("src=")[1].split(/[ >]/)[0];
    src = src.slice(1, -1);
    return src;
  },
};
