let navItemsEmployer = [
  {
    to: "EmployersDashboard",
    label: "داشبورد",
    icon: "mdi-gauge-empty",
    active: false,
    subItems: false,
  },
  {
    to: "EmployerProfile",
    label: "پروفایل",
    icon: "mdi-account-outline",
    active: false,
    subItems: false,
  },
  {
    to: "EmployersJobsList",
    label: "فرصت های کارآموزی من",
    icon: "mdi-briefcase-variant-outline",
    active: false,
    subItems: false,
  },
  {
    to: "SendMessage",
    label: "پیام ها",
    icon: "mdi-message-fast-outline",
    active: false,
    subItems: false,
  },
  {
    to: "Payments",
    label: "پرداخت ها",
    icon: "mdi-credit-card-outline",
    active: false,
    subItems: false,
  },
  {
    to: "SendRequest",
    label: "درخواست همکاری",
    icon: "mdi-package-variant-closed",
    active: false,
    subItems: false,
  },
];

export default navItemsEmployer;
