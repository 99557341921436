export default {
  profileInfo: (state) => {
    return state.profileInfo;
  },

  employerJobs: (state) => {
    return state.employerJobs;
  },
  employerJob: (state) => {
    return state.employerJob;
  },

  messages: (state) => {
    return state.messages;
  },

  requestApplications: (state) => {
    return state.requestApplications;
  },

  dashboardInfo: (state) => {
    return state.dashboardInfo;
  },
};
