export default {
  profileInfo: (state) => {
    return state.profileInfo;
  },

  requestApplications: (state) => {
    return state.requestApplications;
  },

  paymentLink: (state) => {
    return state.paymentLink;
  },

  paymentList: (state) => {
    return state.paymentList;
  },

  dashboardInfo: (state) => {
    return state.dashboardInfo;
  },

  educationLevelOptions() {
    return [
      { value: "1", text: "دیپلم" },
      { value: "2", text: "کاردانی" },
      { value: "3", text: "کارشناسی" },
      { value: "4", text: "کارشناسی ارشد" },
      { value: "5", text: "دکترا" },
    ];
  },
};
