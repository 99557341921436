export default {
  setProfileInfo(state, data) {
    state.profileInfo = data;
  },

  setEmployerJobs(state, data) {
    state.employerJobs = data;
  },
  setEmployerJob(state, data) {
    state.employerJob = data;
  },

  setMessages(state, data) {
    state.messages = data;
  },

  setRequestApplications(state, data) {
    state.requestApplications = data;
  },

  setDashboardInfo(state, data) {
    state.dashboardInfo = data;
  },
};
