<template>
  <router-view />
</template>

<script>
import "@fortawesome/fontawesome-free/css/all.css";
import "@/assets/icomoon/style.css";

export default {
  name: "App",
};
</script>

<style lang="sass">
@import "@/assets/styles/style"
</style>
