import Vue from "vue";
import App from "./App.vue";
// import './registerServiceWorker'
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@babel/polyfill";
import "./plugins/toastification";
import "./plugins/persianDatePicker";

Vue.filter("localNum", function (value) {
  if (!value) return "0";
  value = Number(value);
  return value.toLocaleString("ar");
});

Vue.filter("charactersLimit", function (value, size) {
  if (!value) return "";
  value = value.toString();

  if (value.length <= size) {
    return value;
  }
  return value.substr(0, size) + "...";
});

Vue.filter("charactersLimitTwo", function (value, size) {
  if (!value) return "";
  value = value.toString();

  if (value.length <= size) {
    return value;
  }
  return value.substr(0, size);
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    var usertoken = JSON.stringify(window.localStorage.getItem("shabakeToken"));
    const authUser = JSON.parse(usertoken);
    if (authUser) {
      next();
    } else {
      next({ name: "UserLogin" });
    }
  }
  next();
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
