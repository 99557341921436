export default {
  jobs: (state) => {
    return state.jobs;
  },
  jobDetails: (state) => {
    return state.jobDetails;
  },
  jobCategories: (state) => {
    return state.jobCategories;
  },
  jobCategoryOptions: (state) => {
    const jobCategoryOptions = [];
    state.jobCategories.forEach((item) => {
      const pushItem = {
        value: item,
        text: item,
      };
      jobCategoryOptions.push(pushItem);
    });
    return jobCategoryOptions;
  },

  categories: (state) => {
    return state.categories;
  },
  categoryOptions: (state) => {
    const categoryOptions = [];
    state.categories.forEach((item) => {
      const pushItem = {
        value: item.id,
        text: item.title,
        type: item.type,
      };
      categoryOptions.push(pushItem);
    });
    return categoryOptions;
  },

  interns: (state) => {
    return state.interns;
  },
  internDetails: (state) => {
    return state.internDetails;
  },

  employers: (state) => {
    return state.employers;
  },
  employerDetails: (state) => {
    return state.employerDetails;
  },
  employerJobs: (state) => {
    return state.employerJobs;
  },

  blogs: (state) => {
    return state.blogs;
  },
  dashboardBlogs: (state) => {
    return state.dashboardBlogs;
  },
  blogDetails: (state) => {
    return state.blogDetails;
  },
  categoriesBlog: (state) => {
    return state.categoriesBlog;
  },

  ads: (state) => {
    return state.ads;
  },
  adDetails: (state) => {
    return state.adDetails;
  },

  events: (state) => {
    return state.events;
  },
  eventDetails: (state) => {
    return state.eventDetails;
  },

  banners: (state) => {
    return state.banners;
  },

  packages: (state) => {
    return state.packages;
  },
  packageDetails: (state) => {
    return state.packageDetails;
  },

  aboutPageContent: (state) => {
    return state.aboutPageContent;
  },

  homePageContent: (state) => {
    return state.homePageContent;
  },

  rulePageContent: (state) => {
    return state.rulePageContent;
  },

  teamList: (state) => {
    return state.teamList;
  },

  companies: (state) => {
    return state.companies;
  },

  faqList: (state) => {
    return state.faqList;
  },

  quizList: (state) => {
    return state.quizList;
  },

  countries: (state) => {
    return state.countries;
  },
  countryOptions: (state) => {
    const countryOptions = [];
    state.countries.forEach((item) => {
      const pushItem = {
        value: item.country,
        text: item.country,
        id: item._id,
      };
      countryOptions.push(pushItem);
    });
    return countryOptions;
  },
};
