export default {
  authUser(state, data) {
    state.token = data.token;
  },

  setOtpCode(state, data) {
    state.otpCode = data;
  },

  setLoginCheck(state, data) {
    state.loginCheck = data;
  },

  setShabakeToken(state, data) {
    state.shabakeToken = data;
  },

  clearAuth(state) {
    state.token = null;
  },

  setLoadingBtn(state, data) {
    state.loadingBtn = data;
  },

  setSendCode(state, data) {
    state.sendCode = data;
  },

  // Alert Handles ---------------------------------------

  setPostCheck(state, data) {
    state.postCheck = data;
  },

  setInfo(state, data) {
    state.checkAlert = 4;
    state.infoMessage = data;
  },

  setWarning(state, data) {
    state.checkAlert = 3;
    state.warningMessage = data;
  },

  setError(state, data) {
    state.checkAlert = 2;
    state.dangerMessage = data;
  },

  setSuccess(state, data) {
    state.checkAlert = 1;
    state.successMessage = data;
  },

  expireAlert(state) {
    state.checkAlert = 0;
    state.successMessage = null;
    state.dangerMessage = null;
    state.warningMessage = null;
    state.infoMessage = null;
  },

  setLoading(state, data) {
    state.loadingCheck = data.status;
    state.loadingMessage = data.message;
  },
};
