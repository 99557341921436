import axios from "axios";
import alert from "@/lang/AlertMessages.js";
import label from "@/lang/StaticLabels.js";

export default {
  // Employer Profile Actions -------------------------------------------------------------------------------
  getProfileInfo({ dispatch, commit }) {
    axios
      .get("employer/get-employer-info")
      .then((res) => {
        commit("setProfileInfo", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  editProfile({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .patch("employer/employers-update", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.update,
            redirect: false,
          },
          { root: true },
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 800);
      });
  },

  editProfileLogo({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("employer/employers-add-companyLogo", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.changeAvatar,
            redirect: false,
          },
          { root: true },
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 800);
      });
  },

  editProfileBanner({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("employer/employers-add-bannerImage", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.upload,
            redirect: false,
          },
          { root: true },
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 800);
      });
  },

  //  Jobs Actions ---------------------------------------------------------------
  getEmployerJobs({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true },
    );
    axios
      .get("jobs/get-employer-jobs" + data)
      .then((res) => {
        commit("setEmployerJobs", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  getEmployerJob({ dispatch, commit }, data) {
    axios
      .get("jobs/get-employer-job-details/" + data)
      .then((res) => {
        commit("setEmployerJob", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  addJob({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("jobs/create-jobs", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: "EmployersJobsList",
          },
          { root: true },
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 800);
      });
  },

  editJob({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .patch("jobs/edit-job/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: "EmployersJobsList",
          },
          { root: true },
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 800);
      });
  },

  deleteJob({ dispatch }, data) {
    axios
      .delete("jobs/delete-job/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true },
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  // Messages Actions -------------------------------------------------------------------------------
  getMessages({ dispatch, commit }) {
    axios
      .get("messages/get-messages")
      .then((res) => {
        commit("setMessages", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  readMessage({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .patch("messages/message/" + data + "/read")
      .then(() => {
        return true;
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 800);
      });
  },

  // Employer Request Application Actions -------------------------------------------------------------------------------
  getRequestApplications({ dispatch, commit }) {
    axios
      .get("application/get-request-applications")
      .then((res) => {
        commit("setRequestApplications", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  AnswerRequestApplication({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .patch(
        "application/employer-answer-request-applications/" + data.id,
        data.value,
        {
          headers: { "Content-Type": "multipart/form-data" },
        },
      )
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.answerRequest,
            redirect: false,
          },
          { root: true },
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 800);
      });
  },

  // Dashboard Informations  -----------------------------------------------------------------------------------
  getDashboardInfo({ dispatch, commit }) {
    axios
      .get("employer/get-employer-count-info")
      .then((res) => {
        commit("setDashboardInfo", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },
};
