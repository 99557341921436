<template>
  <div class="footer">
    <footer>
      <v-container fluid>
        <v-row>
          <v-col cols="12" lg="4" md="6" class="pb-0 mb-0">
            <v-row class="align-center">
              <v-col cols="2" class="pb-0">
                <div class="logo-footer">
                  <img src="@/assets/images/shabakeh/logo-3.png" width="100%" />
                </div>
              </v-col>
              <v-col cols="10" class="pb-0">
                <h1 class="text-p-white">شـــبــکه همواره با شماسـت</h1>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <p class="p-lh-m text-justify ps-lg-15">
                  مفهوم شبکه یعنی ارتباط و ما در شبکه بستری را فراهم کرده‌ایم تا
                  ارتباط بین کارآموزان و کارفرمایان را تسهیل کنیم. با انجام تست
                  های آنلاین مبتنی بر هوش مصنوعی، استعدادیابی می‌شوید و در زمینه
                  استعداد خود مسیر آیندتان را هموار می‌کنید. از آغاز تا رسیدن به
                  هدفتان ما همواره در کنارتان هستیم.
                </p>
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col
            cols="6"
            lg="2"
            md="6"
            class="mt-3 d-flex flex-column align-center"
          >
            <ul>
              <li>
                <h3>برای کارآموزان</h3>
              </li>
              <li class="mt-3" v-for="menu in menuA" :key="menu.id">
                <router-link :to="menu.link" class="p-fs-l">
                  {{ menu.text }}
                </router-link>
              </li>
            </ul>
          </v-col> -->
          <v-col
            cols="6"
            lg="2"
            md="6"
            class="mt-3 d-flex flex-column align-center">
            <ul>
              <li>
                <h3>دسترسی سریع</h3>
              </li>
              <li class="mt-3" v-for="menu in menuB" :key="menu.id">
                <router-link :to="menu.link" class="p-fs-l">
                  {{ menu.text }}
                </router-link>
              </li>
            </ul>
          </v-col>
          <v-col
            cols="6"
            lg="2"
            md="6"
            class="mt-3 d-flex flex-column align-center">
            <ul>
              <li>
                <h3>صفحات</h3>
              </li>
              <li class="mt-3" v-for="menu in menuC" :key="menu.id">
                <router-link :to="menu.link" class="p-fs-l">
                  {{ menu.text }}
                </router-link>
              </li>
            </ul>
          </v-col>
          <v-col cols="6" lg="2" md="6" class="footer-app text-center mt-3">
            <h4 class="text-start ms-5 ps-3">ما را دنبال کنید</h4>
            <v-row>
              <v-col
                cols="6"
                class="d-flex justify-center text-center mb-0 px-0">
                <a
                  class="social-item"
                  target="_blank"
                  href="https://www.linkedin.com/in/shabake-%D8%A2%DB%8C%D9%86%D8%AF%D9%87-%DA%AF%D8%B3%D8%AA%D8%B1-%D8%B4%D8%A8%DA%A9%D9%87-b95079320/">
                  <v-icon>mdi-linkedin</v-icon>
                </a>
              </v-col>
              <v-col cols="6" class="text-center mb-0 px-0">
                <a
                  class="social-item"
                  target="_blank"
                  href="https://www.instagram.com/shabake.app?igsh=MW94aTdiZGZzZWVmZA==">
                  <v-icon>mdi-instagram</v-icon>
                </a>
              </v-col>
            </v-row>

            <!-- <a
              href="#"
              class="mt-5 bg-p-black px-3 py-2 radius-p-n d-flex align-center justify-center"
            >
              <div>
                <span class="text-p-white text-cenetr">دریافت از </span>
                <h1 class="text-p-white text-end">App Store</h1>
              </div>
              <img
                src="@/assets/images/shabakeh/apple-logo.png"
                class="ms-2"
                width="25%"
              />
            </a>
            <a
              href="#"
              class= "mt-4 bg-p-black px-3 py-2 radius-p-n d-flex align-center justify-center"
            >
              <div>
                <span class="text-p-white text-cenetr">دریافت از </span>
                <h1 class="text-p-white text-end">Goohle Play</h1>
              </div>
              <img
                src="@/assets/images/shabakeh/game.png"
                class="ms-2"
                width="20%"
              />
            </a> -->
          </v-col>
          <v-col cols="2" class="d-flex mt-4 justify-end">
            <div class="footer-namad">
              <img src="@/assets/images/shabakeh/enemad-1.png" width="80%" />
            </div>
            <div class="footer-namad ms-2">
              <!--              <img src="@/assets/images/shabakeh/enamad-3.png" width="90%"/>-->
              <a
                referrerpolicy="origin"
                target="_blank"
                href="https://trustseal.enamad.ir/?id=316548&Code=idPqq9EOzrPz4k4Zc8Qk"
                ><img
                  referrerpolicy="origin"
                  src="https://trustseal.enamad.ir/logo.aspx?id=316548&Code=idPqq9EOzrPz4k4Zc8Qk"
                  alt=""
                  style="cursor: pointer"
                  Code="idPqq9EOzrPz4k4Zc8Qk"
              /></a>
            </div>
          </v-col>
        </v-row>
        <hr class="hr-footer" />
        <v-row class="align-center">
          <v-col cols="12" lg="6" class="role-footer">
            <h6 class="p-fw-normal">
              کلیه حقوق مادی و معنوی این وب سایت متعلق به سایت شبکه می باشد.
            </h6>
          </v-col>
          <v-col cols="12" lg="6" class="power-footer">
            <p class="text-end mb-0 text-p-readonly p-fs-n p-fw-normal">
              design and developed by
              <a href="https://persianfrontend.com/" target="_blank"
                >Persian Frontend</a
              >
            </p>
          </v-col>
        </v-row>
      </v-container>
    </footer>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import alert from "@/lang/AlertMessages.js";
import label from "@/lang/StaticLabels.js";

export default {
  name: "TheFooter",
  computed: {
    ...mapGetters({}),
  },
  data() {
    return {
      alert,
      label,
      version: process.env.VUE_APP_VERSION,
      menuA: [
        {
          id: "1",
          text: "جستجوی فرصت های کارآموزی",
          link: { name: "JobsList" },
        },
        { id: "2", text: "جستجوی کارآموزان", link: { name: "InternsList" } },
        { id: "4", text: "هشدارهای شغلی", link: "#" },
        { id: "5", text: "ذخیره شده های من", link: "#" },
      ],
      menuB: [
        {
          id: "1",
          text: "داشبورد کارفرما",
          link: { name: "Signin", query: { type: "Employer" } },
        },
        {
          id: "4",
          text: "داشبورد کارآموز",
          link: { name: "Signin", query: { type: "Intern" } },
        },
        { id: "7", text: "هوش مصنوعی", link: { name: "GuizList" } },
        { id: "5", text: "بسته های اشتراکی", link: { name: "Package" } },
      ],
      menuC: [
        { id: "1", text: "درباره ما", link: { name: "About" } },
        { id: "3", text: " تماس با ما", link: { name: "Contact" } },
        { id: "6", text: "سوالات متداول", link: { name: "Faq" } },
        { id: "4", text: "قوانین و مقررات", link: { name: "Rules" } },
      ],
    };
  },
  beforeCreate() {},
};
</script>
