<template>
  <div class="header">
    <v-app-bar flat color="#fafafa" class="header__menu show-res" height="78px">
      <v-row class="align-center">
        <v-col cols="5" class="d-flex align-center">
          <div
            class="me-1 panel-icon-menu"
            @click="togglePanelSidebar()"
            elevation="0"
            fab
            v-if="!panelDrawer && token">
            <v-icon size="32" class="">mdi-menu-open</v-icon>
          </div>
          <div v-if="token">
            <router-link :to="{ name: 'Signin' }" class="d-flex align-center">
              <!-- <v-icon class="p-fs-xxxhuge"> mdi-account-check-outline </v-icon> -->
              <h6 class="text-p-forth ms-2">پنل کاربری</h6>
            </router-link>
          </div>
          <div v-else>
            <router-link :to="{ name: 'Signin' }" class="d-flex align-center">
              <v-icon class="p-fs-xxxhuge">
                mdi-account-arrow-left-outline
              </v-icon>
            </router-link>
          </div>
        </v-col>
        <v-col cols="5" class="mt-2" v-if="token">
          <img src="@/assets/images/shabakeh/logo-3.png" width="40%" />
        </v-col>
        <v-col cols="5" class="mt-2" v-else>
          <img src="@/assets/images/shabakeh/logo-green.png" width="100%" />
        </v-col>
        <v-col cols="2" class="login-header d-flex justify-end">
          <div
            class="ms-5 mt-2"
            @click="toggleSidebar()"
            elevation="0"
            fab
            v-if="!drawer">
            <i class="fas fa-bars p-fs-xxxl text-p-forth"></i>
          </div>
          <!-- <button class="btn-p-first ms-5 px-3 py-3">
            ایجاد فرصت کارآموزی
          </button> -->
        </v-col>
      </v-row>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
      right
      width="90%"
      height="100vh"
      class="sidebar show-res">
      <v-row class="align-center justify-center">
        <v-col cols="6" class="d-flex align-center justify-center">
          <img src="@/assets/images/shabakeh/logo-green.png" width="100%" />
        </v-col>
        <v-col cols="6" class="login-header d-flex justify-end align-center">
          <div v-if="token">
            <router-link :to="{ name: 'Signin' }" class="d-flex align-center">
              <v-icon class="p-fs-xxxhuge"> mdi-account-check-outline </v-icon>
              <h6 class="text-p-forth ms-2">پنل کاربری</h6>
            </router-link>
          </div>
          <div v-else>
            <router-link :to="{ name: 'Signin' }" class="d-flex align-center">
              <v-icon class="p-fs-xxxhuge">
                mdi-account-arrow-left-outline
              </v-icon>
            </router-link>
          </div>
          <div
            @click="toggleSidebar()"
            class="drawer-hide ms-5 mt-2"
            width="25"
            height="63"
            contain
            v-if="drawer">
            <v-icon>mdi-close</v-icon>
          </div>
        </v-col>
      </v-row>

      <v-list dir="rtl" class="sidebar-items d-flex flex-column pt-10">
        <template v-for="(nav, navIndex) in navItems">
          <v-list-item
            v-if="nav.to"
            :key="`${navIndex}-a`"
            :to="{ name: nav.to }"
            link>
            <v-list-item-content>
              <v-list-item-title class="d-flex align-center">
                <v-icon class="me-2 icon-sidebar">{{ nav.icon }}</v-icon>
                <span
                  class="text-start text-p-forth p-fw-bold"
                  style="width: 200px">
                  {{ nav.label }}
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>

      <v-divider
        class="mt-5"
        style="border-color: #1ca774; opacity: 0.2"></v-divider>
      <!-- <v-row dir="rtl" class="mt-5">
        <v-col cols="12" class="d-flex align-center">
          <v-icon class="me-1">mdi-map-marker-outline</v-icon>
          <h5 class="text-p-muted mt-2">
            تهران- سید خندان - خیابان گلها کوچه هیرمند - طبقه سوم - پلاک ۹ شرکت
            آینده گستر شبکه
          </h5>
        </v-col>
      </v-row> -->
      <v-row>
        <!-- <v-col cols="12" class="d-flex align-center pt-0">
          <v-icon class="me-1">mdi-phone-outline</v-icon>
          <h4 class="text-p-muted mt-2">۰۲۱-۲۶۷۲۲۷۹۳</h4>
        </v-col> -->
        <v-col cols="12" class="d-flex align-center pt-0">
          <v-icon class="me-1">mdi-email-outline</v-icon>
          <h4 class="text-p-muted mt-2">farnumTire@gmail.com</h4>
          <h3></h3>
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col cols="12" class="d-flex justify-center share-icon">
          <a
            target="_blank"
            href="https://www.instagram.com/shabake.app?igsh=MW94aTdiZGZzZWVmZA=="
            class="me-3">
            <span class="icon-instagram text-p-muted p-fs-xxxl"></span>
          </a>
          <a
            target="_blank"
            href="https://www.linkedin.com/in/shabake-%D8%A2%DB%8C%D9%86%D8%AF%D9%87-%DA%AF%D8%B3%D8%AA%D8%B1-%D8%B4%D8%A8%DA%A9%D9%87-b95079320/"
            class="me-3">
            <span class="icon-linkedin-logo text-p-muted p-fs-xxxl"></span>
          </a>
        </v-col>
      </v-row>
    </v-navigation-drawer>

    <v-navigation-drawer
      v-model="panelDrawer"
      app
      right
      width="90%"
      height="100vh"
      class="sidebar show-res">
      <v-row class="align-center justify-center sidebar__avatar-wrapper">
        <v-col cols="12" class="login-header d-flex justify-end align-center">
          <div
            @click="togglePanelSidebar()"
            class="drawer-hide ms-5 mt-2"
            width="25"
            height="63"
            contain
            v-if="panelDrawer">
            <v-icon>mdi-close</v-icon>
          </div>
        </v-col>
        <v-col cols="12" v-if="shabakeRole == 'employer'">
          <div class="panel-logo d-flex justify-center">
            <img
              :src="profile.companyLogoUrl"
              width="140px"
              height="135px"
              :alt="profile.companyName" />
          </div>
          <h3 class="text-p-third text-center mt-2">
            {{ profile.companyName }}
          </h3>
        </v-col>
        <v-col cols="12" v-else>
          <div class="panel-logo">
            <img
              :src="profileIntern.profilePictureUrl"
              width="140px"
              height="135px"
              :alt="profileIntern.lastName" />
          </div>
          <div class="d-flex align-center justify-center">
            <v-icon size="16">mdi-cellphone</v-icon>
            <h6 class="text-p-muted text-center ms-2 mt-2">
              {{ profileIntern.mobileNumber }}
            </h6>
          </div>
        </v-col>
      </v-row>

      <v-list
        dir="rtl"
        class="sidebar-items d-flex flex-column ms-5"
        v-if="shabakeRole == 'employer'">
        <template v-for="(nav, navIndex) in navItemsEmployer">
          <v-list-item
            v-if="nav.to"
            :key="`${navIndex}-a`"
            :to="{ name: nav.to }"
            link>
            <v-list-item-content>
              <v-list-item-title class="d-flex align-center">
                <v-icon class="me-2 panel-icon-sidebar">{{ nav.icon }}</v-icon>
                <span
                  class="text-start text-p-forth p-fw-bold"
                  style="width: 200px">
                  {{ nav.label }}
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list-item key="233-a" @click="logout" light>
          <v-list-item-icon>
            <v-icon class="me-2 text-p-first">mdi-information-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <span
                class="text-start text-p-forth p-fw-bold"
                style="width: 200px">
                خروج
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list dir="rtl" class="sidebar-items d-flex flex-column ms-5" v-else>
        <template v-for="(nav, navIndex) in navItemsIntern">
          <v-list-item
            v-if="nav.to"
            :key="`${navIndex}-a`"
            :to="{ name: nav.to }"
            link>
            <v-list-item-content>
              <v-list-item-title class="d-flex align-center">
                <v-icon class="me-2 panel-icon-sidebar">{{ nav.icon }}</v-icon>
                <span
                  class="text-start text-p-forth p-fw-bold"
                  style="width: 200px">
                  {{ nav.label }}
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list-item key="233-a" @click="logout" light>
          <v-list-item-icon>
            <v-icon class="me-2 text-p-first">mdi-information-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <span
                class="text-start text-p-forth p-fw-bold"
                style="width: 200px">
                خروج
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <div class="header__menu hide-res py-0">
      <v-container class="py-0">
        <v-row class="align-center">
          <v-col cols="6" lg="2" class="d-flex justify-center align-center">
            <img src="@/assets/images/shabakeh/logo-3.png" width="40%" />
            <h1 class="ms-2 text-p-third">شبکه</h1>
          </v-col>
          <v-col cols="6" lg="8" class="hide-res d-flex justify-center">
            <div v-for="menu in menuItem" :key="menu.id">
              <v-btn :to="{ name: menu.to }" text>
                {{ menu.text }}
              </v-btn>
            </div>
          </v-col>
          <v-col cols="6" lg="2" class="login-header d-flex justify-center">
            <div v-if="token">
              <router-link
                :to="{ name: 'Signin' }"
                class="d-flex align-center mt-2">
                <v-icon class="ms-2 p-fs-huge">
                  mdi-account-check-outline
                </v-icon>
                <h5 class="text-p-forth ms-2">پنل کاربری</h5>
              </router-link>
            </div>
            <div v-else>
              <router-link
                :to="{ name: 'Signin' }"
                class="d-flex align-center mt-2">
                <v-icon class="ms-2 p-fs-huge login-icon"> mdi-login </v-icon>
                <h5 class="text-p-forth ms-2">ورود / ثبت نام</h5>
              </router-link>
            </div>

            <button
              class="btn-p-first ms-5 px-3 py-3"
              v-if="shabakeRole == 'employer'"
              @click="$router.push({ name: 'AddEmployersJob' })">
              ایجاد فرصت کارآموزی
            </button>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import alert from "@/lang/AlertMessages.js";
import label from "@/lang/StaticLabels.js";
import navItems from "@/containers/navbar.js";
import navItemsEmployer from "@/containers/navbarSidebar.js";
import navItemsIntern from "@/containers/navbarSidebarIntern.js";

export default {
  name: "TheHeader",
  computed: {
    ...mapGetters({
      profile: "employerX/profileInfo",
      profileIntern: "internX/profileInfo",
    }),
  },
  data() {
    return {
      alert,
      label,
      drawer: null,
      panelDrawer: null,
      navItems,
      navItemsEmployer,
      navItemsIntern,
      search: " ",
      token: localStorage.getItem("shabakeToken"),
      shabakeRole: localStorage.getItem("shabakeRole"),
      menuItem: [
        { id: 1, text: "صفحه اصلی", to: "Home" },
        { id: 6, text: "معرفی شبکه", to: "About" },
        { id: 2, text: "فرصت های کارآموزی", to: "JobsList" },
        { id: 3, text: "کارفرمایان", to: "EmployersList" },
        { id: 4, text: "کارآموزان", to: "InternsList" },
        { id: 7, text: "رویداد ها", to: "EventsList" },
        { id: 8, text: "تست هوش مصنوعی", to: "GuizList" },
        { id: 5, text: "مقالات", to: "BlogList" },
      ],
    };
  },

  methods: {
    toggleSidebar() {
      this.drawer = !this.drawer;
    },
    togglePanelSidebar() {
      this.panelDrawer = !this.panelDrawer;
    },
    logout() {
      this.$store.dispatch("logout");
    },
  },
  mounted() {
    if (this.shabakeRole && this.shabakeRole === "employer") {
      this.$store.dispatch("employerX/getProfileInfo");
    }
    if (this.shabakeRole && this.shabakeRole === "intern") {
      this.$store.dispatch("internX/getProfileInfo");
    }
  },
};
</script>
