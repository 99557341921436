import Vue from "vue";
import VueRouter from "vue-router";
import GlobalGuards from "@/guards/Global";
import label from "@/lang/StaticLabels.js";

import TheContainer from "@/containers/TheContainer";
import TheContainerPanel from "@/containers/TheContainerPanel";
const Page404 = () => import("@/views/errors/Page404");
const Page403 = () => import("@/views/errors/Page403");
const Page500 = () => import("@/views/errors/Page500");
const PageUnderConstruction = () =>
  import("@/views/errors/PageUnderConstruction");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Container",
    component: TheContainer,
    redirect: "/home",
    children: [
      {
        path: "home",
        name: "Home",
        meta: {
          label: label.page.dashboard,
          requiresAuth: false,
        },
        component: () => import("@/views/pages/Home"),
      },
      {
        path: 'events-list',
        name: "EventsList",
        meta: {
          label: label.page.dashboard,
          requiresAuth: false,
        },
        component: () => import("@/views/pages/Events.vue"),
      },
      {
        path: "profile",
        name: "Profile",
        meta: {
          label: label.page.profile,
          requiresAuth: false,
        },
        component: () => import("@/views/auth/Profile"),
      },
      {
        path: "users",
        name: "Users",
        meta: {
          label: label.page.users,
          requiresAuth: false,
        },
        component: () => import("@/views/pages/users"),
      },
      {
        path: "aboutUs",
        name: "About",
        meta: {
          label: label.page.aboutUs,
          requiresAuth: false,
        },
        component: () => import("@/views/pages/other/AboutView"),
      },
      {
        path: "contactUs",
        name: "Contact",
        meta: {
          label: label.page.contactUs,
          requiresAuth: false,
        },
        component: () => import("@/views/pages/other/ContactView"),
      },
      {
        path: "faq",
        name: "Faq",
        meta: {
          label: label.page.faq,
          requiresAuth: false,
        },
        component: () => import("@/views/pages/other/FaqView"),
      },
      {
        path: "packages",
        name: "Package",
        meta: {
          label: label.page.packages,
          requiresAuth: false,
        },
        component: () => import("@/views/pages/other/Package"),
      },
      {
        path: "rules",
        name: "Rules",
        meta: {
          label: label.page.rules,
          requiresAuth: false,
        },
        component: () => import("@/views/pages/other/Rules"),
      },
      {
        path: "quiz",
        name: "Quiz",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        redirect: "/quiz/list",
        children: [
          {
            path: "list",
            name: "GuizList",
            component: () => import("@/views/pages/quiz/List"),
            meta: {
              label: "لیست تست های هوش مصنوعی",
              requiresAuth: false,
            },
          },
          {
            path: "details/:id",
            name: "GuizDetails",
            component: () => import("@/views/pages/quiz/details"),
            meta: {
              label: "تست هوش مصنوعی",
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "jobs",
        name: "Jobs",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        redirect: "/jobs/list",
        children: [
          {
            path: "list",
            name: "JobsList",
            component: () => import("@/views/pages/jobs/List"),
            meta: {
              label: label.page.jobs,
              requiresAuth: false,
            },
          },
          {
            path: "details/:id",
            name: "JobDetails",
            component: () => import("@/views/pages/jobs/Details"),
            meta: {
              label: "جزئیات فرصت کارآموزی",
              requiresAuth: false,
            },
          },
        ],
      },
      {
        path: "employers",
        name: "Employers",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        redirect: "/employers/list",
        children: [
          {
            path: "list",
            name: "EmployersList",
            component: () => import("@/views/pages/employers/List"),
            meta: {
              label: "کارفرمایان",
              requiresAuth: false,
            },
          },
          {
            path: "details/:id",
            name: "EmployersDetails",
            component: () => import("@/views/pages/employers/Details"),
            meta: {
              label: "جزئیات کارفرما",
              requiresAuth: false,
            },
          },
        ],
      },
      {
        path: "interns",
        name: "Interns",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        redirect: "/interns/list",
        children: [
          {
            path: "list",
            name: "InternsList",
            component: () => import("@/views/pages/interns/List"),
            meta: {
              label: "کارآموزان",
              requiresAuth: false,
            },
          },
          {
            path: "details/:id",
            name: "InternDetails",
            component: () => import("@/views/pages/interns/Details"),
            meta: {
              label: "جزئیات کارآموزان",
              requiresAuth: false,
            },
          },
        ],
      },
      {
        path: "blog",
        name: "Blog",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        redirect: "/blog/list",
        children: [
          {
            path: "list",
            name: "BlogList",
            component: () => import("@/views/pages/blog/List"),
            meta: {
              label: "مقالات",
              requiresAuth: false,
            },
          },
          {
            path: "details/:id",
            name: "BlogDetails",
            component: () => import("@/views/pages/blog/Details"),
            meta: {
              label: "جزئیات مقاله",
              requiresAuth: false,
            },
          },
        ],
      },

      {
        path: "rejected",
        name: "RejectedTemplate",
        meta: {
          label: label.page.noAccess,
          requiresAuth: false,
        },
        component: () => import("@/views/pages/RejectedTemplate"),
      },

      {
        path: "/signin",
        name: "Signin",
        meta: {
          label: label.page.register,
        },
        component: () => import("@/views/auth/Signin"),
        beforeEnter(to, from, next) {
          GlobalGuards.login(to, from, next);
        },
      },
    ],
  },

  {
    path: "/employer",
    name: "EmployerPanel",
    redirect: "/employer/dashboard",
    component: TheContainerPanel,
    children: [
      {
        path: "dashboard",
        name: "EmployersDashboard",
        meta: {
          label: "داشبورد کارفرما",
          requiresAuth: false,
        },
        component: () => import("@/views/pages/panel/employer/Dashboard"),
      },

      {
        path: "profile",
        name: "EmployerProfile",
        meta: {
          label: "تنظیمات پروفایل",
          requiresAuth: false,
        },
        component: () => import("@/views/pages/panel/employer/Profile"),
      },
      {
        path: "message",
        name: "SendMessage",
        meta: {
          label: "مدیریت پیام ها",
          requiresAuth: false,
        },
        component: () => import("@/views/pages/panel/employer/Message"),
      },
      {
        path: "payments",
        name: "Payments",
        meta: {
          label: "مدیریت پرداخت ها",
          requiresAuth: false,
        },
        component: () => import("@/views/pages/panel/employer/Payments"),
      },
      {
        path: "sendRequest",
        name: "SendRequest",
        meta: {
          label: "درخواست همکاری",
          requiresAuth: false,
        },
        component: () => import("@/views/pages/panel/employer/Request"),
      },
      {
        path: "jobs",
        name: "EmployersJobsList",
        meta: {
          label: "مدیریت فرصت های آموزی",
          requiresAuth: true,
        },
        component: () => import("@/views/pages/panel/employer/jobs/List"),
      },
      {
        path: "jobs/add",
        name: "AddEmployersJob",
        meta: {
          label: "ایجاد آگهی جدید",
          requiresAuth: true,
        },
        component: () => import("@/views/pages/panel/employer/jobs/Add"),
      },
      {
        path: "jobs/edit/:id",
        name: "EditEmployersJob",
        meta: {
          label: "ویرایش آگهی",
          requiresAuth: true,
        },
        component: () => import("@/views/pages/panel/employer/jobs/Edit"),
      },
    ],
  },

  {
    path: "/intern",
    name: "InternPanel",
    redirect: "/intern/dashboard",
    component: TheContainerPanel,
    children: [
      {
        path: "dashboard",
        name: "InternDashboard",
        meta: {
          label: "داشبورد کارآموز",
          requiresAuth: false,
        },
        component: () => import("@/views/pages/panel/intern/Dashboard"),
      },
      {
        path: "profile",
        name: "InternProfile",
        meta: {
          label: "تنظیمات پروفایل",
          requiresAuth: false,
        },
        component: () => import("@/views/pages/panel/intern/Profile"),
      },
      {
        path: "message",
        name: "InternMessages",
        meta: {
          label: "مدیریت پیام ها",
          requiresAuth: false,
        },
        component: () => import("@/views/pages/panel/intern/Message"),
      },
      {
        path: "payments",
        name: "InternPayments",
        meta: {
          label: "مدیریت پرداخت ها",
          requiresAuth: false,
        },
        component: () => import("@/views/pages/panel/intern/Payments"),
      },
      {
        path: "sendRequest",
        name: "InternRequests",
        meta: {
          label: "درخواست های من",
          requiresAuth: false,
        },
        component: () => import("@/views/pages/panel/intern/Request"),
      },
      {
        path: "quiz/results",
        name: "InternQuizResults",
        meta: {
          label: "تست های هوش مصنوعی من",
          requiresAuth: false,
        },
        component: () => import("@/views/pages/panel/intern/Quiz"),
      },
    ],
  },

  // other Pages - without container

  {
    path: "/pages",
    redirect: "/pages/404",
    name: "Pages",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "404",
        name: "Page404",
        component: Page404,
      },
      {
        path: "403",
        name: "Page403",
        component: Page403,
      },
      {
        path: "500",
        name: "Page500",
        component: Page500,
      },
      {
        path: "underConstruction",
        name: "PageUnderConstruction",
        component: PageUnderConstruction,
      },
    ],
  },

  {
    path: "*",
    component: Page404,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: () => ({
    behavior: "smooth",
    y: 0,
    x: 0,
  }),
  routes,
});

router.beforeEach((to, from, next) => GlobalGuards.beforeEach(to, from, next));
router.afterEach((to, from) => GlobalGuards.afterEach(to, from));

export default router;
