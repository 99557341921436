import axios from "axios";
import alert from "@/lang/AlertMessages.js";

export default {
  // Quizes -------------------------------------------------------------------------------

  getQuizResults({ dispatch, commit }) {
    axios
      .get("quiz/get-user-result-quiz")
      .then((res) => {
        commit("setQuizResults", res.data.result);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  submitQuiz({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("quiz/user-quizzes/" + data.id + "/submit", data.value)
      .then((res) => {
        commit("setResult", res.data.output);
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submitQuiz,
            redirect: false,
          },
          { root: true },
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },
};
