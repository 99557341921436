<template>
  <div class="header">
    <the-alerts />

    <v-navigation-drawer v-model="drawer" app right width="300" class="sidebar">
      <!--  Employer Panel's Menu  -->
      <div class="sidebar__avatar-wrapper" v-if="shabakeRole == 'employer'">
        <div class="panel-logo">
          <img
            :src="profile.companyLogoUrl"
            width="140px"
            height="135px"
            :alt="profile.companyName"
            v-if="profile.companyLogo" />
          <img
            src="@/assets/images/avatars/default-avatar.jpg"
            width="140px"
            height="135px"
            :alt="profile.companyName"
            v-else />
        </div>
        <div class="mt-4">
          <h3 class="text-p-third text-center">
            {{ profile.companyName }}
          </h3>
          <div
            class="d-flex align-center justify-center mt-1"
            v-if="profile.city">
            <v-icon class="text-p-muted p-fs-xl">mdi-map-marker</v-icon>
            <h6 class="text-p-muted ms-1">
              {{ profile.city }}
            </h6>
          </div>
        </div>
      </div>

      <!--  Intern Panel's Menu  -->
      <div class="sidebar__avatar-wrapper" v-else>
        <div class="panel-logo">
          <img
            :src="profileIntern.profilePictureUrl"
            width="140px"
            height="135px"
            :alt="profileIntern.lastName"
            v-if="profileIntern.profilePicture" />
          <img
            src="@/assets/images/avatars/default-avatar.jpg"
            width="140px"
            height="135px"
            :alt="profileIntern.lastName"
            v-else />
        </div>
        <div class="mt-4">
          <div class="d-flex align-center justify-center">
            <v-icon size="16">mdi-cellphone</v-icon>
            <h6 class="text-p-muted text-center ms-2">
              {{ profileIntern.mobileNumber }}
            </h6>
          </div>

          <div
            class="d-flex align-center justify-center mt-1"
            v-if="profile.city">
            <v-icon class="text-p-muted p-fs-xl">mdi-map-marker</v-icon>
            <h6 class="text-p-muted ms-1">
              {{ profile.city }}
            </h6>
          </div>
        </div>
      </div>
      <v-list class="d-flex flex-column" v-if="shabakeRole == 'employer'">
        <template v-for="(nav, navIndex) in navItemsEmployer">
          <v-list-item
            v-if="nav.to"
            :key="`${navIndex}-a`"
            :to="{ name: nav.to }"
            link
            light>
            <v-list-item-icon>
              <v-icon small>{{ nav.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ nav.label }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            v-if="nav.subItems"
            :key="`${navIndex}-b`"
            :prepend-icon="`${nav.icon} fa-em`"
            v-model="nav.active"
            append-icon="fas fa-chevron-down fa-sm">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ nav.label }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <span v-for="(sub, subIndex) in nav.subItems" :key="subIndex">
              <v-list-item :to="{ name: sub.to }" link dark>
                <v-list-item-icon>
                  <v-icon small>{{ sub.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ sub.label }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <hr v-if="sub.divider" />
            </span>
          </v-list-group>
        </template>
        <v-list-item key="233-a" @click="logout" light>
          <v-list-item-icon>
            <v-icon small>mdi-information-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>خروج</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list class="d-flex flex-column" v-else>
        <template v-for="(nav, navIndex) in navItemsIntern">
          <v-list-item
            v-if="nav.to"
            :key="`${navIndex}-a`"
            :to="{ name: nav.to }"
            link
            light>
            <v-list-item-icon>
              <v-icon small>{{ nav.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ nav.label }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            v-if="nav.subItems"
            :key="`${navIndex}-b`"
            :prepend-icon="`${nav.icon} fa-em`"
            v-model="nav.active"
            append-icon="fas fa-chevron-down fa-sm">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ nav.label }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <span v-for="(sub, subIndex) in nav.subItems" :key="subIndex">
              <v-list-item :to="{ name: sub.to }" link dark>
                <v-list-item-icon>
                  <v-icon small>{{ sub.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ sub.label }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <hr v-if="sub.divider" />
            </span>
          </v-list-group>
        </template>
        <v-list-item key="233-a" @click="logout" light>
          <v-list-item-icon>
            <v-icon small>mdi-information-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>خروج</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TheAlerts from "@/components/TheAlerts";
import alert from "@/lang/AlertMessages.js";
import label from "@/lang/StaticLabels.js";
import navItemsEmployer from "@/containers/navbarSidebar.js";
import navItemsIntern from "@/containers/navbarSidebarIntern.js";

export default {
  name: "TheHeader",
  components: {
    TheAlerts,
  },
  data() {
    return {
      alert,
      label,
      navItemsEmployer,
      navItemsIntern,
      drawer: null,
      version: process.env.VUE_APP_VERSION,
      shabakeRole: localStorage.getItem("shabakeRole"),
    };
  },
  computed: {
    ...mapGetters({
      loadingBtn: "loadingBtn",
      profile: "employerX/profileInfo",
      profileIntern: "internX/profileInfo",
    }),
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
  mounted() {
    if (this.shabakeRole && this.shabakeRole === "employer") {
      this.$store.dispatch("employerX/getProfileInfo");
    }
    if (this.shabakeRole && this.shabakeRole === "intern") {
      this.$store.dispatch("internX/getProfileInfo");
    }
  },
};
</script>
