import axios from "axios";
import router from "@/router";
import alert from "@/lang/AlertMessages.js";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.shabakeToken;
axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

export default {
  sendCode({ commit, dispatch }, data) {
    commit("setLoginCheck", true, { root: true });
    axios
      .post("common/signup-signin", data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        setTimeout(() => {
          dispatch(
            "successPOSTHandler",
            {
              server: res,
              message: alert.success.codeSent,
              redirect: false,
            },
            { root: true },
          );
        }, 800);
        commit("setSendCode", true);
        setTimeout(() => {
          commit("setSendCode", false);
        }, 1000);
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoginCheck", false, { root: true });
        }, 1000);
      });
  },

  login({ dispatch, commit }, data) {
    commit("setLoginCheck", true);
    axios
      .post("common/signup-signin", data.value, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        commit("authUser", res.data);
        localStorage.setItem("shabakeToken", res.data.token);
        localStorage.setItem("shabakeRole", data.role);
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + res.data.token;
        if (data.role == "employer") {
          router.push({ name: "EmployerPanel" });
        }
        if (data.role == "intern") {
          router.push({ name: "InternPanel" });
        }
        setTimeout(() => {
          commit("setSuccess", alert.success.login, { root: true });
        }, 800);
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoginCheck", false);
        }, 800);
      });
  },

  logout({ commit }) {
    localStorage.removeItem("shabakeToken");
    localStorage.removeItem("shabakeRole");
    axios.defaults.headers.common["Authorization"] = "";
    commit("clearAuth");
    router.push({ name: "Signin" });
  },

  // Alert and Errors Handler ------------------------------------------------------
  successPOSTHandler({ commit }, data) {
    if (data.server && data.server.status === 200) {
      commit("setSuccess", data.message, { root: true });
      commit("setPostCheck", true, { root: true });
      setTimeout(() => {
        commit("setPostCheck", false, { root: true });
      }, 800);
      if (data.redirect) {
        setTimeout(() => {
          router.push({ name: data.redirect });
        }, 2500);
      }
    } else if (data.server && data.server.status === 201) {
      commit("setSuccess", data.message, { root: true });
      commit("setPostCheck", true, { root: true });
      setTimeout(() => {
        commit("setPostCheck", false, { root: true });
      }, 800);
      if (data.redirect) {
        setTimeout(() => {
          router.push({ name: data.redirect });
        }, 2500);
      }
    } else if (data.server.data && data.server.data.message != "") {
      commit("setError", data.server.data.message, { root: true });
      commit("setPostCheck", false, { root: true });
    } else {
      commit("setError", alert.error.server, { root: true });
      commit("setPostCheck", false, { root: true });
    }
  },

  successPOSTHandlerSimple({ commit }, data) {
    if (data.server && data.server.status === 200) {
      commit("setSuccess", data.message, { root: true });
    } else if (data.server.data && data.server.data.message != "") {
      commit("setError", data.server.data.message, { root: true });
    } else {
      commit("setError", alert.error.server, { root: true });
    }
  },

  errorPOSTHandler({ dispatch, commit }, data) {
    commit("setPostCheck", false, { root: true });
    if (data.response && data.response.status === 403) {
      commit("setError", alert.error.access, { root: true });
    } else if (data.response && data.response.status === 401) {
      dispatch("logout", null, { root: true });
      setTimeout(() => {
        commit("setError", alert.error.needToLogin, { root: true });
      }, 1000);
    } else if (data.response.data.message && data.response.data.message != "") {
      commit("setError", data.response.data.message, { root: true });
    } else {
      commit("setError", alert.error.server, { root: true });
    }
  },

  errorGETHandler({ dispatch, commit }, data) {
    commit("setPostCheck", false, { root: true });
    if (data.response && data.response.status === 403) {
      router.push({ name: "Page403" });
    } else if (data.response && data.response.status === 401) {
      commit("setError", alert.error.logout, { root: true });
      dispatch("logout", null, { root: true });
    } else if (
      data.response &&
      data.response.data &&
      data.response.data.message &&
      data.response.data.message != ""
    ) {
      commit("setError", data.response.data.message, { root: true });
    } else {
      commit("setError", alert.error.server, { root: true });
    }
  },
};
