export default {
  setProfileInfo(state, data) {
    state.profileInfo = data;
  },

  setRequestApplications(state, data) {
    state.requestApplications = data;
  },

  setPaymentLink(state, data) {
    state.paymentLink = data;
  },

  setPaymentList(state, data) {
    state.paymentList = data;
  },

  setDashboardInfo(state, data) {
    state.dashboardInfo = data;
  },
};
