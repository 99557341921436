// Global Guards
/* eslint-disable */

const beforeEach = (to, from, next) => {
  var usertoken = JSON.stringify(window.localStorage.getItem("shabakeToken"));
  const authUser = JSON.parse(usertoken);
  if (to.meta.requiresAuth) {
    if (authUser) {
      next();
    } else {
      next({ name: "Signin" });
    }
  }
  next();
};

const login = (to, from, next) => {
  if (localStorage.getItem("shabakeToken")) {
    var userType = window.localStorage.getItem("shabakeRole");
    if (userType == "employer") {
      next({ name: "EmployerPanel" });
    } else {
      next({ name: "InternPanel" });
    }
  }
  next();
};

const afterEach = (to, from) => {
  // Put your guard actions here
};

export default {
  beforeEach,
  login,
  afterEach,
};
