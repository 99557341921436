<template>
  <v-layout style="height: 60px; width: 100%" class="border rounded p-relative">
    <v-bottom-navigation v-model="active" color="first" style="z-index: 300">
      <v-btn :to="{ name: 'InternProfile' }">
        <v-icon>mdi-account-circle</v-icon>
      </v-btn>

      <v-btn :to="{ name: 'BlogList' }">
        <v-icon>mdi-inbox-full-outline</v-icon>
      </v-btn>

      <v-btn :to="{ name: 'JobsList' }">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-btn :to="{ name: 'Home' }">
        <v-icon>mdi-home</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-layout>
</template>

<script>
import alert from "@/lang/AlertMessages.js";
import label from "@/lang/StaticLabels.js";

export default {
  name: "TheMenu",
  components: {},
  data() {
    return {
      alert,
      label,
      active: true,

      // options: [
      //   {
      //     id: 4,
      //     icon: "icon-gift",
      //     title: "جوایز",
      //     path: { name: "GiftList"},
      //   },
      //   {
      //     id: 3,
      //     icon: "icon-percent",
      //     title: "تخفیف‌ها",
      //     path: { name: "Discount"},

      //   },
      //   {
      //     id: 2,
      //     icon: "icon-category",
      //     title: "دسته‌بندی",
      //     path: { name: "CategoryList"},
      //   },
      //   {
      //     id: 1,
      //     icon: "icon-home-2",
      //     title: "خانه",
      //     path: { name: "Home" },
      //   },
      // ],
    };
  },
};
</script>
<style lang="sass">
.v-item-group.v-bottom-navigation
  position: fixed
  border-top-left-radius: 20px
  border-top-right-radius: 20px
  box-shadow: 0px -6px 16px 4px rgba(0, 0, 0, 0.17) !important
  height: 60px !important
</style>
